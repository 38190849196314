@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply text-[10px];
    /* @apply font-sans; */
    @apply box-border;
    @apply outline-1;
    @apply custom;
    padding: 0;
    margin: 0;
  }
  *::-webkit-scrollbar {
    display: none;
  }
  * {
    scrollbar-width: none;
    font-family: 'Carter One', system-ui;
    box-sizing: 'box-border';
  }
}

@font-face {
  font-family: 'Impact';
  src: url('/public/fonts/Impact.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}

.bg-contain-center {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .bg-contain-pointer {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
  }
  .bg-cover-center {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .bg-cover-pointer {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
  }

.paused {
  animation-play-state: paused;
}

.custom-range-mobile {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  background: linear-gradient(to right, #f59e0b 0%, #f59e0b 50%, #ef4444 50%, #ef4444 100%);
  border-radius: 5vw;
  outline: none; /* Remove outline */
  padding: 0; /* Remove padding */
}

/* WebKit/Blink Browsers */
.custom-range-mobile::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 5.5vw; /* Thumb width */
  height: 5.5vw; /* Thumb height */
  background-color: transparent; /* Thumb color */
  background-image: url('../public/images/input-image.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%; /* Round thumb */
  cursor: pointer; /* Pointer cursor */
}

/* Firefox */
.custom-range-mobile::-moz-range-thumb {
  width: 5.5vw; /* Thumb width */
  height: 5.5vw; /* Thumb height */
  background-color: transparent; /* Thumb color */
  background-image: url('../public/images/input-image.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%; /* Round thumb */
  cursor: pointer; /* Pointer cursor */
}

/* IE */
.custom-range-mobile::-ms-thumb {
  width: 5.5vw; /* Thumb width */
  height: 5.5vw; /* Thumb height */
  background-color: transparent; /* Thumb color */
  background-image: url('../public/images/input-image.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%; /* Round thumb */
  cursor: pointer; /* Pointer cursor */
}

/* Track for Firefox */
.custom-range-mobile::-moz-range-track {
  width: 100%;
  height: 12px;
  background: linear-gradient(to right, #f59e0b 0%, #f59e0b 50%, #ef4444 50%, #ef4444 100%);
  border-radius: 50px;
  outline: none;
}

/* Track for IE */
.custom-range-mobile::-ms-track {
  width: 100%;
  height: 12px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.custom-range {
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(to right, #f59e0b 0%, #f59e0b 50%, #ef4444 50%, #ef4444 100%);
  border-radius: 50px;
  outline: none; /* Remove outline */
  padding: 0; /* Remove padding */
}

/* WebKit/Blink Browsers */
.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px; /* Thumb width */
  height: 30px; /* Thumb height */
  background-color: transparent; /* Thumb color */
  background-image: url('../public/images/input-image.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%; /* Round thumb */
  cursor: pointer; /* Pointer cursor */
}

/* Firefox */
.custom-range::-moz-range-thumb {
  width: 30px; /* Thumb width */
  height: 30px; /* Thumb height */
  background-color: transparent; /* Thumb color */
  background-image: url('../public/images/input-image.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%; /* Round thumb */
  cursor: pointer; /* Pointer cursor */
}

/* IE */
.custom-range::-ms-thumb {
  width: 30px; /* Thumb width */
  height: 30px; /* Thumb height */
  background-color: transparent; /* Thumb color */
  background-image: url('../public/images/input-image.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%; /* Round thumb */
  cursor: pointer; /* Pointer cursor */
}

/* Track for Firefox */
.custom-range::-moz-range-track {
  width: 100%;
  height: 12px;
  background: linear-gradient(to right, #f59e0b 0%, #f59e0b 50%, #ef4444 50%, #ef4444 100%);
  border-radius: 50px;
  outline: none;
}

/* Track for IE */
.custom-range::-ms-track {
  width: 100%;
  height: 12px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.nth-child-4n-mr-0:nth-child(4n) {
  margin-right: 0px;
}

.custom-range::after {
  position: abosolute;
  content: '';
  width: 20px;
  height: 20px;
  background: #000;
  top: 0;
  left: 0;
}
.custom-range::after {
  position: abosolute;
  content: '';
  width: 20px;
  height: 20px;
  background: #000;
  top: 0;
  left: 0;
}
.custom-range::after {
  position: abosolute;
  content: '';
  width: 20px;
  height: 20px;
  background: #000;
  top: 0;
  left: 0;
}
.custom-range::after {
  position: abosolute;
  content: '';
  width: 20px;
  height: 20px;
  background: #000;
  top: 0;
  left: 0;
}
.custom-range::after {
  position: abosolute;
  content: '';
  width: 20px;
  height: 20px;
  background: #000;
  top: 0;
  left: 0;
}
.custom-range::after {
  position: abosolute;
  content: '';
  width: 20px;
  height: 20px;
  background: #000;
  top: 0;
  left: 0;
}
.custom-range::after {
  position: abosolute;
  content: '';
  width: 20px;
  height: 20px;
  background: #000;
  top: 0;
  left: 0;
}
.font-carter {
  font-family: 'Carter One', system-ui;
}

.font-impact {
  font-family: 'Impact', sans-serif;
}

.font-archivo {
  font-family: 'Archivo Black', sans-serif;
}

.font-pixelify {
  font-family: 'Pixelify Sans', sans-serif;
}

@keyframes customAnimation {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: translateY(-5px);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(-20px);
  }
}
.font-stroke {
  -webkit-text-stroke: 8px #fafafa;
}
/* .font-gothic {
  font-family: 'Gothic A1', sans-serif;
}
.font-oswald {
  font-family: 'Oswald', sans-serif;
} */
.bg-conic-gradient {
  background: conic-gradient(#f2d142 0%, #f2d142 90%, #ed9d52 90%, #ed9d52 100%);
}

@layer components {
  .custom {
    color: #101010;
  }

  .bg-conic-gradient {
    background: conic-gradient(#f2d142 0%, #f2d142 90%, #ed9d52 90%, #ed9d52 100%);
  }
  .box-shadow-custom {
    box-shadow: rgba(0, 0, 0, 0.45) 18px 15px 17px;
  }
  .box-shadow-button {
    box-shadow: rgba(0, 0, 0, 0.45) 9px 8px 17px;
  }
  .bg-contain-center {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .bg-cover-center {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .box-shadow-y {
    box-shadow: 0rem 0rem 5rem 2rem rgba(0, 0, 0, 1);
  }

  .flexRow {
    @apply flex flex-row justify-center items-center;
  }
  .flexCol {
    @apply flex flex-col justify-center items-center;
  }
  .transition-all-300 {
    transition: all 300ms ease-in-out;
  }
  .transition-all-150 {
    transition: all 150ms ease-in-out;
  }
  .webkit-box-none {
    -webkit-box-shadow: 0 0 0 100rem white inset;
    box-shadow: 0 0 0 100rem white inset;
  }
  .op-50 {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .op-100 {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .light-red {
    box-shadow: 0rem 0rem 0.8rem 0.2rem rgba(236, 59, 35, 0.9);
  }
  .box-shadow-s {
    box-shadow: 0rem 0rem 2rem 0.2rem rgba(0, 0, 0, 0.05);
  }
  .box-shadow-m {
    box-shadow: 0rem 0rem 2.5rem 0.3rem rgba(0, 0, 0, 0.1);
  }
  .box-shadow-l {
    box-shadow: 0rem 0rem 3rem 0.5rem rgba(0, 0, 0, 0.13);
  }
  .box-shadow-xl {
    box-shadow: 0rem 0rem 5rem 1rem rgba(0, 0, 0, 0.15);
  }
}

input:disabled {
  background-color: white;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
.perspective-1 {
  perspective: '10px';
}

.ripple {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  transform: scale(0);
  animation: ripple 0.6s linear;
}
